import {useEffect, useState, useCallback, useRef} from "react";
import moment from "moment-timezone";
import 'moment/locale/ru';
import api from "../../api";
import LoadingBar from "react-top-loading-bar";

const AppointmentForm = ({
  appointment,
  afterSave,
  onCancel
}) => {
  const [agree, setAgree] = useState(appointment.agree || false);
  const [intervalId, setIntervalId] = useState(appointment.intervalId || '')
  const intervalRef = useRef();
  const nameRef = useRef();
  const phoneRef = useRef();
  const vacancyRef = useRef();
  const commentRef = useRef();
  const emailRef = useRef();

  const [date, setDate] = useState();
  const [error, setError] = useState();
  const [validationErrors, setValidationErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [now] = useState(moment.now());
  const [intervals, setIntervals] = useState([]);

  const tz = moment.tz.guess();

  const updateDate = useCallback((newValue) => {
    setIsLoading(true);
    api.get('/diary/appointments/get_time_intervals.html', {
      hash: appointment.hash,
      date: moment(newValue).format(),
      tz
    })
      .then(result => {
        if (result.intervals) {
          setError(null);
          setIntervals(result.intervals)
        } else {
          setError('Сервер вернул неожиданный результат: ' + JSON.stringify(result));
        }
      })
      .catch(e => {
        setError(e.message)
      })
      .finally(() => {
        setDate(newValue)
        setIsLoading(false);
        setProgress(100);
      })
  }, [appointment, tz]);

  const onSubmit = useCallback((e) => {
    e.preventDefault();

    setIsLoading(true);
    api.post('/diary/appointments/save.html', {
      hash: appointment.hash,
      intervalId,
      date: moment(date).format(),
      tz,
      name: nameRef.current.value,
      phone: phoneRef.current.value,
      email: emailRef.current.value,
      comment: commentRef.current.value,
      vacancy: vacancyRef.current.value,
      agree
    })
      .then(result => {
        setError(null);
        setIsLoading(false);
        return result;
      })
      .then(result => {
        if (result.validationErrors) {
          setValidationErrors(result.validationErrors)
          setProgress(100)
        } else if (result.success) {
          afterSave();
          // setProgress(100);  // Прогресс будет обновляться в родителе, а здесь комментируем, чтобы избежать ошибки с утечкой памяти
        } else {
          setError('Сервер вернул неожиданный результат: ' + JSON.stringify(result));
          setProgress(100);
        }
      })
      .catch(e => {
        setError(e.message)
        setIsLoading(false);
        setProgress(100);
      })
  }, [appointment, agree, date, tz, intervalId, afterSave]);

  useEffect(() => {
    // Подгружаем время
    updateDate(moment(appointment.date));
  }, [appointment, updateDate]);

  return (
    <div>
      <LoadingBar color='#007bff' progress={progress} onLoaderFinished={() => setProgress(0)}
        transitionTime={200}
        loaderSpeed={300}
        waitingTime={500}
      />
      <h4>Выберите удобное время для общения с консультантом</h4>

      <form onSubmit={onSubmit}>
        <div className="form-group mt-3">
          <label htmlFor="exampleFormControlInput1" className="">Дата</label>
          <div>
            <button type="button" className="btn btn-light btn-outline-secondary" title="Предыдущее число" disabled={isLoading || date <= now} onClick={e => {
              e.preventDefault();
              updateDate(+date - 24 * 3600 * 1000);
            }}>
              &lt;&lt;
            </button>
            <span className="btn" style={{cursor: 'default'}}>{moment(date).format('ll, dddd')}</span>
            <button type="button" className="btn btn-light btn-outline-secondary" title="Следующее число" disabled={isLoading} onClick={e => {
              e.preventDefault();
              updateDate(+date + 24 * 3600 * 1000);
            }}>
              &gt;&gt;
            </button>
          </div>
        </div>
        <div className="form-group mt-3">
          <label htmlFor="interval">Период времени <span className="small text-secondary">(в часовом поясе {moment.tz.guess()})</span></label>
          <select className="form-control" id="interval" ref={intervalRef} value={intervalId} onChange={e => setIntervalId(e.target.value)}>
            <option value="">(Выберите значение)</option>
            {intervals.map(interval => (
              <option key={interval.id} value={interval.id} disabled={!interval.isFree && interval.id !== appointment.intervalId}>
                {moment(interval.start).local().format('в HH:mm')}
                {interval.id !== appointment.intervalId || " - текущее время"}
                {(!interval.isFree && interval.id !== appointment.intervalId) && " - недоступно"}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group mt-3">
          <label htmlFor="fio" className="">Как вас зовут</label>
          <input type="text" className="form-control" id="fio" defaultValue={appointment.name || ''} ref={nameRef} placeholder="Укажите ФИО" />
        </div>
        <div className="form-group mt-3">
          <label htmlFor="phone" className="">Ваш контактный телефон</label>
          <input type="text" className="form-control" id="phone" defaultValue={appointment.phone || ''} ref={phoneRef} placeholder="Укажите ваш контактный телефон" />
        </div>
        <div className="form-group mt-3">
          <label htmlFor="email" className="">Ваша контактная почта</label>
          <input type="text" className="form-control" id="email" defaultValue={appointment.email || ''} ref={emailRef} placeholder="Укажите адрес электронной почты" />
        </div>
        <div className="form-group mt-3">
          <label htmlFor="vacancy" className="">Название вакансии</label>
          <input type="text" className="form-control" id="vacancy" defaultValue={appointment.vacancy || ''} ref={vacancyRef} placeholder="Например, Технико-коммерческий представитель" />
        </div>
        <div className="form-group mt-3">
          <label htmlFor="comment" className="">Дополнительная информация</label>
          <textarea className="form-control" id="comment" defaultValue={appointment.comment || ''} ref={commentRef} placeholder="Например, дополнительные способы связи" />
        </div>

        <div className="form-check mt-3">
          <input type="checkbox" className="form-check-input" id="agree" checked={agree} onChange={e => setAgree(e.target.checked)} />
          <label className="form-check-label" htmlFor="agree">
            Я принимаю условия
            {' '}
            <a href="https://crm.ibchr.ru/doc/resume/termsofuse.pdf" target="_blank" rel="noopener noreferrer">пользовательского соглашения</a>
          </label>
        </div>

        {error && (
          <div className="alert alert-danger">
            <h4>Ошибка</h4>
            {error}
          </div>
        )}

        {validationErrors.length > 0 && (
          <div className="alert alert-warning mt-3">
            Обнаружены ошибки:
            <ul>
              {validationErrors.map(text => (
                <li key={text}>{text}</li>
              ))}
            </ul>
          </div>
        )}

        <div className="form-group my-4">
          <button type="submit" disabled={!agree || isLoading} className="btn btn-primary">Отправить</button>
          {onCancel && <button type="button" disabled={isLoading} className="btn btn-secondary ms-2" onClick={onCancel}>Отменить изменения</button>}
        </div>
      </form>
    </div>
  );
};

export default AppointmentForm
