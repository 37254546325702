import logo from './logo/Ibchr255x93.png'

export const HeaderIbchr = () => (
  <div className="bg-light my-0 py-3 mb-3">
    <div className="container d-md-flex">
      <div className="justify-content-start">
        <a href="https://ibchr.ru/">
          <img alt="Кадровое агентство IBC Human Resources" src={logo} className="img-fluid"
               style={{maxHeight: '70px'}}/>
        </a>
      </div>
      <div className="flex-fill">{''}</div>
      <div className="text-end d-none d-sm-block" style={{color: '#50606E'}}>
        <p className="h4">Кадровое агентство IBC Human Resources</p>
        <p>Поиск квалифицированных кадров</p>
      </div>
    </div>
  </div>
)
