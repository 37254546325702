const Error404Index = props => {
  return (
    <div className="text-center">
      <h1><i className="bi bi-emoji-frown"/></h1>
      Извините, такой страницы нет
    </div>
  )
}

export default Error404Index
