import config from './config';

class Api {
  #baseUrl = 'http://localhost:8888';

  constructor(baseUrl) {
    this.#baseUrl = baseUrl;
  }

  get(path, params = null) {
    return this.query(path, 'GET', params);
  }

  post(path, params = null) {
    return this.query(path, 'POST', params);
  }

  patch(path, params = null) {
    return this.query(path, 'PATCH', params);
  }

  delete(path, params = null) {
    return this.query(path, 'DELETE', params);
  }

  query(path, method, params) {
    const url = new URL(this.#baseUrl + path);
    const options = {
      method,
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      },
    };

    if (params) {
      if (method === 'GET') {
        const search = url.searchParams;

        for (const [key, value] of Object.entries(params)) {
          search.set(key, value);
        }
      } else {
        options.body = JSON.stringify(params);
      }
    }

    return fetch(url.toString(), options)
      .then(response => response.json())
      .then(json => {
        if (json.error) {
          throw new Error(json.error.description, json.error.code);
        }

        return json;
      })
    ;
  }
};

const api = new Api(config.api.url);

export default api;
