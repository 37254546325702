import { BrowserRouter as Router, Switch } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import pages from './pages';
import Header from "./components/Header";
import {templates, TemplateContext} from './TemplateContext';
import {useState} from "react";

function App() {
  const [template, setTemplate] = useState(templates.none)

  return (
    <TemplateContext.Provider value={{template, setTemplate}}>
      <Router>
        <Header />
        <div className="container">
          <Switch>
            {pages}
          </Switch>
        </div>
      </Router>
    </TemplateContext.Provider>
  );
}

export default App;
