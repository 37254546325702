export const HeaderNone = () => (
  <div className="bg-light my-0 py-3 mb-3">
    <div className="container d-md-flex">
      <div className="justify-content-start placeholder placeholder-wave bg-secondary">
        <svg xmlns="http://www.w3.org/2000/svg" width="255" height="93" viewBox="0 0 255 93">
          <rect fill="#aaa" width="255" height="93"/>
        </svg>
      </div>
      <div className="flex-fill d-none d-sm-block placeholder-glow">
        <div className="text-end">
          <p className="h4 placeholder bg-secondary w-75"/>
          <p />
          <p className="placeholder bg-secondary w-50"/>
        </div>
      </div>
    </div>
  </div>
)
