import logo from "./logo/Ibc255x93.png";

export const HeaderIbc = () => (
  <div className="bg-light my-0 py-3 mb-3">
    <div className="container d-md-flex">
      <div className="justify-content-start">
        <a href="https://ct.ibc.ru/">
          <img alt="IBC Corporate Travel" src={logo} className="img-fluid"
             style={{maxHeight: '70px'}}/>
        </a>
      </div>
      <div className="flex-fill">{''}</div>
      <div className="text-end d-none d-sm-block" style={{color: '#50606E'}}>
        <p className="h4">IBC Corporate Travel</p>
        <p>Тревел-агентство с 25-летней историей</p>
      </div>
    </div>
  </div>
)
