import {TemplateContext, templates} from "../../TemplateContext";
import {useContext} from "react";
import {HeaderIbchr} from "./templates/HeaderIbchr";
import {HeaderNone} from "./templates/HeaderNone";
import {HeaderBronevik} from "./templates/HeaderBronevik";
import {HeaderIbc} from "./templates/HeaderIbc";

const headers = {
  [templates.ibchr]: <HeaderIbchr />,
  [templates.bronevik]: <HeaderBronevik />,
  [templates.ibc]: <HeaderIbc />,
  [templates.none]: <HeaderNone />
}

const Header = (props) => {
  const {template} = useContext(TemplateContext);
  return headers[template] || headers[templates.none];
}

export default Header
