import { Route } from "react-router-dom";
import Main from './Main';
import Error404 from './Error404';
import Appointment from './Appointment';

const PagesIndex = [
  <Route path="/appointment/:hash(\w+)" key="appointment" component={Appointment} exact />,
  <Route path="/" key="main" component={Main} exact />,
  <Route path="/" key="not found" component={Error404} />,
];

export default PagesIndex
