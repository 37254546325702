import moment from "moment-timezone";
import 'moment/locale/ru';

const AppointmentView = ({appointment}) => (
  <div>
    <h4>Вы согласовали время для общения с консультантом</h4>
    <dl className="row">
      <dt className="col-md-3 col-xxl-2">Консультант</dt>
      <dd className="col-md-9">{appointment.consultant.name}</dd>

      <dt className="col-md-3 col-xxl-2">Дата</dt>
      <dd className="col-md-9">{moment(appointment.start).local().format('LL, dddd')}</dd>

      <dt className="col-md-3 col-xxl-2">Время</dt>
      <dd className="col-md-9">
        в {moment(appointment.start).local().format('HH:mm')}
        <div className="mdall text-secondary">в часовом поясе {moment.tz.guess()}</div>
      </dd>
      <dt className="col-md-3 col-xxl-2">Ваш телефон</dt>
      <dd className="col-md-9">{appointment.phone}</dd>
    </dl>
    <p>
      Пожалуйста, ожидайте звонка в указанное время.
    </p>
  </div>
);

export default AppointmentView
