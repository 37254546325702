import { ReactComponent as Logo } from './logo/Bronevik.svg'

export const HeaderBronevik = () => (
  <div className="bg-light my-0 py-3 mb-3">
    <div className="container d-md-flex">
      <div className="justify-content-start">
        <a href="https://bronevik.com/">
          <Logo style={{height: '70px'}} />
        </a>
      </div>
      <div className="flex-fill">{''}</div>
      <div className="text-end d-none d-sm-block" style={{color: '#50606E'}}>
        <p className="h4">BRONEVIK.com</p>
        <p>Национальная система бронирования</p>
      </div>
    </div>
  </div>
)
