import {useCallback, useEffect, useRef, useState} from "react";
import {Modal} from "bootstrap";
import api from "../../api";

const CancelButton = ({appointment, afterUpdate}) => {
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [modal, setModal] = useState(null);
  const dialog = useRef();

  useEffect(() => {
    setModal(new Modal(dialog.current))
  }, []);

  const onSubmit = useCallback(() => {
    setIsLoading(true)
    api.post('/diary/appointments/cancel.html', {
      hash: appointment.hash,
    })
      .then(result => {
        setError(null);
        setIsLoading(false);
        return result;
      })
      .then(result => {
        if (result.success) {
          modal.hide();
          afterUpdate();
        } else {
          setError('Сервер вернул неожиданный результат: ' + JSON.stringify(result));
        }
      })
      .catch(e => {
        setError(e.message)
        setIsLoading(false);
      })
  }, [appointment, afterUpdate, modal]);

  const onClose = useCallback(() => {
      modal.hide()
  }, [modal]);

  return (
    <>
      <button className="btn btn-danger ms-2" onClick={() => modal.show()}><i className="bi bi-x-square"/> Отменить звонок</button>
      {/* Modal */}
      <div className="modal fade" ref={dialog} data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Подтвердите отмену</h5>
              <button type="button" className="btn-close" disabled={isLoading} onClick={onClose} />
            </div>
            <div className="modal-body">
              Вы действительно хотите отменить звонок?
              {error && (
                <div className="alert alert-danger mt-3">
                  {error}
                </div>
              )}
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" disabled={isLoading} onClick={onClose}>Нет, не надо</button>
              <button type="button" className="btn btn-primary" disabled={isLoading} onClick={onSubmit}>Да, отменить</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CancelButton
