import React from "react";

export const templates = {
  ibchr: 'ibchr',
  bronevik: 'bronevik',
  ibc: 'ibc',
  none: 'none'
}

export const TemplateContext = React.createContext({
  template: templates.ibchr,
  setTemplate: () => {}
})
