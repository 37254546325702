import {useContext, useEffect} from "react";
import {TemplateContext} from "../../TemplateContext";

const MainIndex = props => {
  const template = useContext(TemplateContext);

  useEffect(() => {
    // Выставляем шаблон для шапки
    template.setTemplate('ibchr')
  }, [template])

  return (
    <div>
      <h3>О компании</h3>

      <p>Кадровое агентство IBC Human Resources работает в России с 1996 года.</p>

      <p>Федеральная сеть представлена городами: Москва, Екатеринбург, Краснодар, Новосибирск и Владивосток.</p>

      <p>Присутствие в ключевых регионах позволяет в кратчайшие сроки находить сотрудников даже в самых удаленных частях
        России.</p>

      <h3>Подбор персонала без границ</h3>

      <p>Поиск кандидатов на вакансии без географических ограничений, с релокацией в любую точку страны и мира вместе с IBC
      Human Resources - это реально! Поиск редких специалистов ведется на всей территории России и стран ближнего
      зарубежья. По желанию работодателя мы организуем переезд кандидатов из города в город или даже из страны в страну.
      Это требования современного мира, в котором размыты границы, и важно обеспечить компании необходимыми
        человеческими ресурсами в кратчайшие сроки.</p>
    </div>
  )
}

export default MainIndex
