import {useCallback, useContext, useEffect, useState} from "react";
import api from "../../api";
import {useParams} from 'react-router-dom';
import View from "./View";
import Form from "./Form";
import CancelButton from "./CancelButton";
import LoadingBar from "react-top-loading-bar";
import "./index.css";
import {TemplateContext} from "../../TemplateContext";

const AppointmentIndex = () => {
  const [appointment, setAppointment] = useState({});
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const {hash} = useParams();
  const template = useContext(TemplateContext);

  const loadAppointment = useCallback(() => {
    setIsLoading(true);
    api.get('/diary/appointments/get.html', {hash})
      .then(result => {
        if (result.appointment) {
          setError(null);
          setAppointment(result.appointment);
          template.setTemplate(result.appointment.template);
        } else {
          setError('Сервер вернул неожиданный результат: ' + JSON.stringify(result));
        }
      })
      .catch(e => {
        setError(e.message)
      })
      .finally(() => {
        setIsLoading(false);
        setProgress(100);
      })
  }, [hash, template]);

  useEffect(() => {
    // Меняем заголовок страницы
    document.title = 'Выбрать время для общения с консультантом';
    loadAppointment();
  }, [loadAppointment]);

  return (
    <div>
      {error && (
        <div className="alert alert-danger">
          <h4>Ошибка</h4>
          {error}
        </div>
      )}

      <LoadingBar color='#007bff' progress={progress} onLoaderFinished={() => setProgress(0)}
        transitionTime={200}
        loaderSpeed={300}
        waitingTime={500}
      />

      {appointment.status === 'new' && (
        <Form appointment={appointment} afterSave={loadAppointment}/>
      )}

      {appointment.status === 'edit' && (
        <Form appointment={appointment} afterSave={loadAppointment} onCancel={loadAppointment}/>
      )}

      {appointment.status === 'used' && (
        <View appointment={appointment}/>
      )}

      {appointment.status === 'cancelled' && (
        <h4>Звонок отменён</h4>
      )}

      {appointment.status === 'used' && appointment.canMoveOrCancel && !isLoading && (
        <div>
          <div className="btn-toggle collapsed link-primary" data-bs-toggle="collapse" aria-expanded="false" data-bs-target="#extra" role="button">Дополнительно</div>
          <div className="collapse mt-2 pt-2" id="extra">
            <div className="d-flex">
              {/*<EditButton appointment={appointment} afterUpdate={loadAppointment} />*/}
              <button className="btn btn-primary" onClick={() => setAppointment({...appointment, status: 'edit'})}><i className="bi bi-pencil-square"/> Внести изменения</button>
              <CancelButton appointment={appointment} afterUpdate={loadAppointment} />
            </div>
          </div>
        </div>
      )}

    </div>
  )
}

export default AppointmentIndex
